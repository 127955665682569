import { Controller } from '@hotwired/stimulus';
import { forEach } from 'lodash';
import SimpleLightbox from 'simple-lightbox';
import toggleFullscreen, {
  fullscreenChange,
  isFullscreen,
} from 'toggle-fullscreen';

export default class extends Controller {
  static targets = [ 'fullscreenCheckbox', 'gallery', 'photo' ];

  connect() {
    console.log(this.hasFullscreenCheckboxTarget);

    if (this.hasFullscreenCheckboxTarget) {
      fullscreenChange((e) => {
        this.onExitFullScreen(e);
      }).catch((e) => {
        // seems to be unsupported in Mobile Safari
        this.isFullScreenSupported = false;
      });
    }

    if (this.hasGalleryTarget) {
      this.lightbox = new SimpleLightbox({
        elements: this.photoTargets,
        closeOnOverlayClick: false,
        beforeSetContent: () => {
          if (this.isGalleryFullScreen()) {
            this.goFullScreen();
          }
        },
        beforeClose: () => {
          this.exitFullScreen();
        },
        beforeDestroy: () => {

        }
      });
    }
  }

  isGalleryFullScreen() {
    return this.hasFullscreenCheckboxTarget && this.fullscreenCheckboxTarget.checked == true;
  }

  goFullScreen() {
    if (!isFullscreen()) {
      toggleFullscreen(document.body);
    }
  }

  exitFullScreen() {
    if (isFullscreen()) {
      toggleFullscreen(document.body);
    }
  }

  onExitFullScreen(e) {
    if (!isFullscreen()) {
      if (this.lightbox) {
        this.lightbox.close();
      }
    }
  }

}
