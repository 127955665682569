import { Controller } from '@hotwired/stimulus';
import { forEach } from 'lodash';
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = [ 'image', 'cropX', 'cropY', 'cropW', 'cropH' ];

  connect() {
    if (this.hasImageTarget) {
      this.cropper = new Cropper(this.imageTarget, {
        viewMode: 1,
        aspectRatio: 4 / 3,
        checkCrossOrigin: false,
        minCropBoxWidth: 100,
        minCropBoxHeight: 75,
        zoomable: false,
        crop: (event) => {
          this.setFormValues(event.detail);
        }
      });
    }
  }

  setFormValues(details) {
    if (details == null) {
      return;
    }

    if (this.hasCropXTarget) {
      this.cropXTarget.value = details.x;
    }
    if (this.hasCropYTarget) {
      this.cropYTarget.value = details.y;
    }
    if (this.hasCropWTarget) {
      this.cropWTarget.value = details.width;
    }
    if (this.hasCropHTarget) {
      this.cropHTarget.value = details.height;
    }
  }
}
