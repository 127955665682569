import { Dropdown } from 'bootstrap';

class NavbarSearch {

  minLength = 3
  maxResults = 15
  query = null
  urls = {}

  constructor(inputElementId, listElementId) {
    this.input = document.getElementById(inputElementId);
    this.list = document.getElementById(listElementId);

    if (this.input != null) {
      this.dropdown = new Dropdown(this.input);

      this.input.addEventListener("keyup", (e) => {
        this.onKeyPress(e)
      });
    }
  }

  enableAutocomplete(data) {
    this.customRenderDropdown(data);
  }

  resetAutocomplete() {
    this.list.innerHTML = '';
  }

  disableAutocomplete() {
  }

  onKeyPress(event) {
    if (this.input.value != this.query) {
      if (this.waitingToSearch != null) {
        clearTimeout(this.waitingToSearch);
        this.waitingToSearch = null;
      }
      this.disableAutocomplete();
      this.query = this.input.value;
      if (this.query.length >= this.minLength) {
        this.waitAndSearch();
      }
    }
  }

  waitAndSearch() {
    this.waitingToSearch = setTimeout(() => {
      this.search();
    }, 1000)
  }

  search() {
    fetch(`/users.json?term=${this.query}&limit=${this.maxResults}`)
      .then((response) => this.results(response))
      .catch((error) => console.error(error));
  }

  results(response) {
    response.json().then((data) => {
      this.renderResults(data);
      this.dropdown.show();
    });
  }

  renderResults(data) {
    this.resetAutocomplete();

    data.forEach((dato) => {
      this.list.innerHTML += `
        <li>
          <a class="dropdown-item text fs-3" href="${dato.url}">
            <img src="${dato.photo}" height="40">
            ${dato.name}
          </a>
        </li>
      `;
    })
  }
}

export default NavbarSearch;
