import { Controller } from '@hotwired/stimulus';
import { forEach } from 'lodash';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [ 'dragZone', 'item' ];

  connect() {
    this.csrfToken = document.querySelector("[name='csrf-token']").content

    this.sortable = Sortable.create(this.dragZoneTarget, {
      handle: '.drag-handler',
      ghostClass: 'drag-ghost',   // Class name for the drop placeholder
      chosenClass: 'drag-chosen', // Class name for the chosen item
      dragClass: 'drag-dragging', // Class name for the dragging item

      onStart: (evt) => {
        const oldIndex = evt.oldIndex;  // element index within parent
      },

      onEnd: (evt) => {
        const item = evt.item;                   // dragged HTMLElement
        const clonedItem = evt.clone             // the clone element
        const sourceList = evt.from;             // previous list
        const mode = evt.pullMode;               // when item is in another sortable: `'clone'` if cloning, `true` if moving
        const targetList = evt.to;               // target list
        const oldIndex = evt.oldIndex;           // element's old index within old parent
        const newIndex = evt.newIndex;           // element's new index within new parent
        const oldIndexD = evt.oldDraggableIndex; // element's old index within old parent, within draggable elements
        const newIndexD = evt.newDraggableIndex; // element's new index within new parent, within draggable elements

        this.disableDragging();

        fetch(item.dataset.repositionUrl, {
          method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
          credentials: 'same-origin', // include, *same-origin, omit
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.csrfToken },
          body: JSON.stringify({ position: newIndex })
        })
        .then((response) => {
          this.enableDragging();
        });
      }
    });
  }

  enableDragging() {
    this.sortable.option('disabled', false);
  }

  disableDragging() {
    this.sortable.option('disabled', true);
  }
}
