import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'fewWordsQuote', 'fewWordsForm' ];

  showFewWordsForm() {
    this.fewWordsQuoteTarget.classList.add('d-none');
    this.fewWordsFormTarget.classList.remove('d-none');
  }
}
