import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'audienceMainskillTypeSelect', 'audienceMainskillSelect', 'audienceSubskillSelect', 'audienceSearchButton' ];

  audienceMainskillTypeChanged() {
    fetch(`/mainskills/list_by_mstype.html?mstype=${this.audienceMainskillTypeSelectTarget.value}`).
    then(response => response.text()).
    then(html => {
      this.audienceMainskillSelectTarget.innerHTML = html;
      M.FormSelect.getInstance(this.audienceMainskillSelectTarget).destroy;
      M.FormSelect.init(this.audienceMainskillSelectTarget, {});
      this.audienceMainskillChanged();
    });
  }

  audienceMainskillChanged() {
    if (this.hasAudienceSearchButtonTarget) {
      this.audienceSearchButtonTarget.href = this.audienceSearchButtonTarget.dataset.url.
                                                  replace('0', this.audienceMainskillSelectTarget.value);
    }

    if (this.hasAudienceSubskillSelectTarget) {
      fetch(`/mainskills/${this.audienceMainskillSelectTarget.value}/subskills/select_list.html`).
      then(response => response.text()).
      then(html => {
        const oldInstance = M.FormSelect.getInstance(this.audienceSubskillSelectTarget);
        if (oldInstance) {
          oldInstance.destroy();
        }

        this.audienceSubskillSelectTarget.innerHTML = html;
        if (html.trim() != '') {
          M.FormSelect.init(this.audienceSubskillSelectTarget, {});
        }
        this.audienceSubskillChanged();
      });
    }
  }

  audienceSubskillChanged() {

  }
}
