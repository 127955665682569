import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap';
import { forEach } from 'lodash';

export default class extends Controller {
  static targets = [ 'deleteLink', 'form', 'results', 'wrapper' ];

  connect() {
    if (this.hasWrapperTarget) {
      this.formDrawer = Offcanvas.getOrCreateInstance(this.wrapperTarget);
    }
  }

  addWork(event) {
    event.preventDefault();

    forEach(this.wrapperTarget.querySelectorAll(`.form-select, .form-control`), (input) => {
      input.value = '';
    });
    this.formTarget.action = this.formTarget.dataset.createUrl;
    this.changeFormMethod('post');
    this.deleteLinkTarget.classList.add('d-none');
    this.showForm();
  }

  editWork(event) {
    event.preventDefault();

    let link = event.target.closest('li');
    let workUrl = link.dataset.fetchUrl;

    fetch(workUrl, {
      cache: 'no-cache',
      credentials: 'same-origin'
    }).
    then(response => response.json()).
    then((data) => {
      if (data.work) {
        forEach(data.work, (value, key) => {
          let formInputElement = this.wrapperTarget.querySelector(`[name="work[${key}]"]`);

          if (formInputElement != null) {
            formInputElement.value = value;
          }
        })

        this.formTarget.action = workUrl;
        this.changeFormMethod('patch');
        this.deleteLinkTarget.classList.remove('d-none');
        this.deleteLinkTarget.href = link.dataset.fetchUrl.replace('.json', '');
        this.showForm();
      }
    });
  }

  // Both successfully saved and not saved Work records call this callback,
  // since the AJAX call is successful!
  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.error == null) {
      window.location = location;
    } else { // There should be errors!
      this.resultsTarget.classList.remove('d-none');
      this.resultsTarget.innerHTML = data.error.join('<br>');
    }
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
  }

  showForm() {
    this.resultsTarget.classList.add('d-none');
    this.formDrawer.show();
  }

  changeFormMethod(method) {
    let methodInput = this.formTarget.querySelector(`input[name="_method"]`)

    if (methodInput != null) {
      methodInput.value = method;
    } else {
      methodInput = document.createElement('input');
      methodInput.type = 'hidden';
      methodInput.name = '_method';
      methodInput.value = method;
      this.formTarget.appendChild(methodInput);
    }
  }
}
