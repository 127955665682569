/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from '@rails/ujs';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Offcanvas, Carousel } from 'bootstrap';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import NavbarSearch from '../search-autocomplete/navbar-search';

Rails.start();

if (process.env.NODE_ENV == 'development') {
  console.log('Starting Fake Sentry intergration!')
} else {
  Sentry.init({
    dsn: 'https://36fbfc1eefc446b9a8e1d55a4ed9cd63@o219249.ingest.sentry.io/1363267',
    environment: process.env.NODE_ENV,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: 'artcom@5.0.0',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

document.addEventListener('DOMContentLoaded', function() {
  new NavbarSearch('navbar-search', 'navbar-search-results');
});
