import { Controller } from '@hotwired/stimulus';
import { forEach } from 'lodash';

export default class extends Controller {
  static targets = [ 'uploadForm', 'uploadFileInput', 'uploadSpinner' ];

  connect() {
    this.uploadFileInputTarget.addEventListener('change', () => {
      this.showSpinner();
      this.hideInput();
      this.uploadFormTarget.submit();
    });
  }

  showSpinner() {
    this.uploadSpinnerTarget.classList.add('d-flex');
    this.uploadSpinnerTarget.classList.remove('d-none');
  }

  hideInput() {
    this.uploadFileInputTarget.classList.add('d-none');
  }
}
